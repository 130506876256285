<template>
  <div class="content">
    <div class="resource-bg">
      <div class="spread">
        <p style="margin-top: 20px;">Resources</p>
      </div>
    </div>
    <div class="video">
      <p>Instructional Video</p>
      <div class="play">
        <video-player class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions">
        </video-player>
      </div>
    </div>
    <div class="instructions">
      <h2>Instructions for Use</h2>
      <p>Download the Instructions for Use insert for the ExactSign SARS-CoV-2 Antigen Rapid Self Test.</p>
      <div @click="download">Download</div>
    </div>
    <div class="questions">
      <div class="title">Frequently Asked Questions</div>
      <div class="asked">
        <div class="left">
          <div class="item">
            <div class="switch" @click="showDetail(1)">
              <p>1. How accurate is the test?</p>
              <img :src="showId === 1 ? upImg : downImg">
            </div>
            <div class="detail" v-if="showId === 1">
              A clinical evaluation was conducted comparing the results obtained using the COVID-19 Antigen Rapid Test Cassette (Nasal Swab) to PCR. Specimens were considered positive if PCR indicated a positive result.
              - For 103 cases of PCR positive, the Relative Sensitivity the COVID-19 Antigen Rapid Test Cassette (Nasal Swab) is 93.2% (96/103).
              - For 250 cases of PCR negative, the Relative Specificity of COVID-19 Antigen Rapid Test Cassette (Nasal Swab) is 99.2% (248/250).
              - For 103 cases of PCR positive and 250 cases of PCR negative, the Relative Accuracy of COVID-19 Antigen Rapid Test Cassette (Nasal Swab) is 97.5% (344/353).
              A usability study was performed by lay person, 150 subjected were enrolled and self-tested with package insert and quick reference guide only, relative sensitivity was 93.75% (30/32), relative specificity was 99.15% (117/118).
              The results showed that the labeling provided with the test kit was comprehensive for its intended population, the ease of use was suitable for its intended population.
              Detection Limit: The detection limit for the COVID-19 Antigen Rapid Test Cassette (Nasal Swab) is 1000 TCID50/mL.
            </div>
          </div>
          <div class="item">
            <div class="switch" @click="showDetail(2)">
              <p>2. Will other diseases affect the result?</p>
              <img :src="showId === 2 ? upImg : downImg">
            </div>
            <div class="detail" v-if="showId === 2">
              No cross reactivity has been observed on testing by following commonly found respiratory/ oropharyngeal pathogens:
              Influenza A virus, Influenza B virus, Adenovirus, Coxsackie virus, Parainfluenza Virus Type1, Parainfluenza Virus Type2, Parainfluenza Virus Type3, Parainfluenza Virus Type4a, Enterovirus, Mumps virus, Respiratory syncytial virus, Rhinovirus, Bordetella pertussis, Haemophilus parainfluenzae, Staphylococcus aureus, Streptococcus agalactiae, Neisseria meningitides, Streptococcus sp. group A, Streptococcus sp. group B, Streptococcus sp. group C, Candida albicans, Human Metapneumovirus (hMPV), Legionella pneumophila, Mycobacterium
              tuberculosis, Mycoplasma pneumoniae, Pneumocystis jirovecii(PJP)-S cerevisiae Recombinant, Pseudomonas aeruginosa, Staphylococcus epidermis, Streptococcus pneumoniae, Streptococcus pyogenes, Streptococcus salivarius, Human coronavirus 229E, Human coronavirus OC43, Human coronavirus NL63 and MERS-coronavirus positive specimens.
              However, a false result due to presence of these organisms at a level higher than tested cannot be ruled out.
            </div>
          </div>
          <div class="item">
            <div class="switch" @click="showDetail(3)">
              <p>3. Will this test hurt?</p>
              <img :src="showId === 3 ? upImg : downImg">
            </div>
            <div class="detail" v-if="showId === 3">
              No, the nasal swab is not sharp and it should not hurt. Sometimes the swab can feel slightly uncomfortable or tickly. If you feel pain, please stop the test and seek advice from a healthcare provider.
            </div>
          </div>
          <div class="item">
            <div class="switch" @click="showDetail(4)">
              <p>4. I have a nosebleed after swabbing my nose. What should I do?</p>
              <img :src="showId === 4 ? upImg : downImg">
            </div>
            <div class="detail" v-if="showId === 4">
              In the unlikely event your nose starts bleeding, apply pressure to your nose until the bleeding stops and consult a healthcare professional. Do not insert the Swab again.            </div>
          </div>
          <div class="item">
            <div class="switch" @click="showDetail(5)">
              <p>5. How do I know that the test was run properly?</p>
              <img :src="showId === 5 ? upImg : downImg">
            </div>
            <div class="detail" v-if="showId === 5">
              A procedural control is included in the test. A colored line appearing in the control line region (C) is considered an internal procedural control. It confirms adequate membrane wicking.            </div>
          </div>
        </div>
        <div class="right">
          <div class="item">
            <div class="switch" @click="showDetail(6)">
              <p>6. What should I do if the result shows positive?</p>
              <img :src="showId === 6 ? upImg : downImg">
            </div>
            <div class="detail" v-if="showId === 6">
              A confirmation PCR test will not be required after a positive RAT. Individual states and territories will provide further information on how a positive RAT will be recorded. Anyone who tests positive is encouraged to contact their GP for support as required.
            </div>
          </div>
          <div class="item">
            <div class="switch" @click="showDetail(7)">
              <p>7. What should I do if the result shows negative?</p>
              <img :src="showId === 7 ? upImg : downImg">
            </div>
            <div class="detail" v-if="showId === 7">
              Negative results may require additional testing to confirm your results if you are symptomatic. If symptomatic, continue antigen testing every 24 hours for 3 days. If asymptomatic, it is likely that you were not infectious at the time the test was taken. A negative test result, however, is not a guarantee that you do not have coronavirus. Please continue to follow social distancing, washing hands regularly and wearing masks as directed.            </div>
          </div>
          <div class="item">
            <div class="switch" @click="showDetail(8)">
              <el-tooltip
                class="item"
                content="Can ExactSign COVID-19 Antigen Rapid Test detect various variants of COVID-19?"
                placement="top-start"
              >
                <p>8. Can ExactSign COVID-19 Antigen Rapid Test detect various variants of COVID-19?</p>
              </el-tooltip>
              <img :src="showId === 8 ? upImg : downImg">
            </div>
            <div class="detail" v-if="showId === 8">
              Yes, ExactSign COVID-19 Antigen Rapid Test can detect Alpha, Beta, Gamma, Delta and Omicron COVID-19 mutants based on the studies conducted so far.
            </div>
          </div>
          <div class="item">
            <div class="switch" @click="showDetail(9)">
              <el-tooltip
                class="item"
                content="Can any substances interfere with the ExactSign COVID-19 Antigen Rapid Test?"
                placement="top-start"
              >
                <p>9. Can any substances interfere with the ExactSign COVID-19 Antigen Rapid Test?</p>
              </el-tooltip>
              <img :src="showId === 9 ? upImg : downImg">
            </div>
            <div class="detail" v-if="showId === 9">
              The COVID-19 Antigen Rapid Test Cassette has been tested for Ambroxol Hydrochloride Tablets (7.5 mg/mL), Nasal antibioitic (Mupirocin Ointment), Mometasone furoate nasal spray (0.05% g/g), Oxymetazoline Hydrochloride Spray, Nin Jiom Pei Pa Kao cough syrup, Beclomethasone Dipropionate Nasal Aerosol, Dextromethorphan Hydrobromide Oral Solution(1.5 mg/ml), Triamcinolone Acetonide Nasal Spray, MucosolvanAmbroxol Hydrochloride Oral Solution, Azelastine Hydrochloride Nasal Spray, Nasal cleansing solution, NaCl (5 g/L), Fluticasone Propionate Nasal Spray, Hyland's 4 Kids Cold Cough Liquid Safe Natural Relief, Physiological Seawater Nasal Spray, Durham's Canker-Rid, Tobramycin Eye Drops, Listerine mouthwash ,Whole blood (4%), Scope mouthwash, Mucin (0.05%). No substances above showed any interference with the test.            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'resources',
  components: {
  },
  data () {
    return {
      downImg: require('@/assets/images/down.png'),
      upImg: require('@/assets/images/up.png'),
      showId: 0,
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0, 3.0],
        autoplay: true,
        muted: false,
        loop: true,
        preload: 'auto',
        language: 'zh-CN',
        aspectRatio: '16:9',
        fluid: true,
        sources: [{
          type: 'video/mp4',
					src: require('@/assets/video/instructionalVideo.mp4')
        }],
        poster: '', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试',
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true
        }
      }
    }
  },
  mounted() {
    document.title = 'btmedtec'
  },
  methods: {
    showDetail(id){
      if (id === this.showId) {
        this.showId = 0
      } else {
        this.showId = id
      }
    },
    //tab切换
    activityChange(id) {
      this.$emit('activity-change', id)
    },
    // pdf下载
    download() {
      axios.get('/export/instruction.pdf', {
        responseType: 'blob'
      }).then(response => {
        let url = window.URL.createObjectURL(new Blob([response.data]))
        let link = document.createElement('a')
        let fname = 'instruction.pdf'
        link.href = url
        link.setAttribute('download', fname)
        link.setAttribute('target', '_blank')
        document.body.appendChild(link)
        link.click();
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  display: flex;
  flex-direction: column;
  .resource-bg {
    width: 100%;
    min-height: 500px;
    background: url('../assets/images/resource_bg.jpg') no-repeat center center;
    background-size: 100% auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    .spread {
      height: 200px;
      width: 550px;
      background: rgba(255,172,142, 0.8);
      display: flex;
      align-items: center;
      p {
        font-size: 40px;
        font-weight: 500;
        color: #fff;
        margin-left: 90px;
      }
    }
  }
  .video {
    width: 100%;
    height: 770px;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      font-size: 34px;
      font-weight: bold;
      margin-top: 100px;
    }
    .play {
      width: 860px;
      height: 480px;
      background: #EEEEEE;
      margin-top: 50px;
    }
  }
  .instructions {
    width: 100%;
    height: 328px;
    background: #ffac8e;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    h2 {
      font-size: 40px;
      margin-top: 85px;
    }
    p {
      font-size: 18px;
      font-weight: 350;
      margin-top: 20px;
    }
    div {
      font-size: 20px;
      font-weight: 400;
      margin-top: 20px;
      padding: 10px 100px;
      border: 2px solid #fff;
      border-radius: 45px;
      cursor: pointer;
      &:hover {
        background: rgba(255, 255, 255, 0.4);
      }
    }
  }
  .questions {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #F5F5F5;
    .title {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 105px;
      font-size: 24px;
      font-weight: 600;
    }
    .asked {
      margin: 85px 70px;
      display: flex;
      .left {
        width: 50%;
      }
      .right {
        width: 50%;
      }
      .item {
        width: 100%;
        display: flex;
        flex-direction: column;
        .switch {
          margin: 0 2%;
          height: 50px;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          padding-bottom: 10px;
          border-bottom: 2px solid #000;
          p {
            font-size: 16px;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          img {
            width: 24px;
            height: 24px;
          }
        }
        .detail {
          margin: 0 3%;
          font-size: 18px;
          font-weight: 300;
          margin-top: 10px;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
</style>