<template>
  <div class="content">
    <div class="home-bg">
      <div class="spread">
        <h1>Instant diagnosis protects the health of you and your family</h1>
        <p>You can use ExactSign™ COVID-19 antigen test to perform a quick self-test and the results can show in 10-15 minutes.</p>
        <p style="margin-top: 20px; cursor: pointer;" @click="learnMore(2)">Learn more about the ExactSign™ COVID-19 antigen test. <img style="margin-bottom: 2px;" src="../assets/images/link.png"></p>
      </div>
    </div>
    <div class="more">
      <div class="img">
        <img src="../assets/images/product.png">
      </div>
      <div class="describe">
        <h3>Convenient and fast self-test for COVID-19</h3>
        <p>Keep healthy and safe with the ExactSign™ COVID-19 antigen self-test. Just follow the instructions, sampling with a nasal swab, insert the sampled swab into the pre-filled extraction tube, and after thorough mixing, add three drops of the solution to the sample well of the test cassette. Read the results in 10-15 minutes. Test can be performed by yourself at home.</p>
        <div class="btn" @click="learnMore(3)">Learn More</div>
      </div>
    </div>
    <div class="benefits">
      <div class="title">Advantages</div>
      <div class="info">
        <div class="item">
          <img src="../assets/images/easy.png">
          <h2>Painless</h2>
          <div style="text-align:center;">
            <p>Avoid the discomfort of nasopharyngeal swab sampling</p>
          </div>
        </div>
        <div class="item">
          <img src="../assets/images/quick.png">
          <h2>Convenient</h2>
          <p>Easy to operate, Interpret result in 10-15 minutes</p>
        </div>
        <div class="item">
          <img src="../assets/images/overall.png">
          <h2>Accuracy</h2>
          <div style="text-align:center;">
            <p>Excellent performance compared to molecular methods</p>
          </div>
        </div>
        <div class="item">
          <img src="../assets/images/shelf.png">
          <h2>Storage</h2>
          <div style="text-align:center;">
            <p>24-month shelf life Room temperature storage (2-30°C)</p>
          </div>
        </div>
        <div class="item">
          <img src="../assets/images/accepted.png">
          <h2>Multinational Certification</h2>
          <div style="text-align:center;">
            <p>Manufacturer certification: Europe CE, Germany PEI, Australia TGA, Brazil ANVISA etc</p>
          </div>
        </div>
      </div>
    </div>
<!--    <div class="video">-->
<!--      <p>Instructional Video</p>-->
<!--      <div class="play">-->
<!--        <video-player ref="videoPlayer"-->
<!--          class="video-player vjs-custom-skin"-->
<!--          :playsinline="true"-->
<!--          :options="playerOptions">-->
<!--        </video-player>-->
<!--      </div>-->
<!--      <div class="all" @click="learnMore(2)">More Resources</div>-->
<!--    </div>-->
    <div class="works">
      <div class="left">
        <img style="width: 50%; margin-bottom: 25px;" src="../assets/images/coronavirus.jpg">
        <img src="../assets/images/reagent.png">
      </div>
      <div class="right">
        <h4>How It Works</h4>
        <p class="known">The ExactSign™ COVID-19 Antigen Rapid Test Cassette (Nasal Swab) is a qualitative, lateral flow immunoassay for the detection of the N protein of SARS-CoV-2 in Nasal Swab. In this test, antibody specific to the N protein of SARS-CoV-2 is separately coated on the test line regions of the test cassette. During testing, the extracted specimen reacts with the antibody to N protein of SARS-CoV-2 that are coated onto particles. The mixture migrates up the membrane to react with the antibody to N protein of SARSCoV-2 on the membrane and generate one line in the test regions. The presence of this line of the test regions indicates a positive result. To serve as a procedural control, a line will always appear in the control region if the test has performed properly.</p>
      </div>
    </div>
    <div class="specifications">
      <div class="switch" @click="showDetail">
        <p>Specifications</p>
        <img :src="isShowSpecifi ? upImg : downImg">
      </div>
    </div>
    <div v-if="isShowSpecifi" class="list">
      <div class="wrap">
        <div class="l">
          <p>Test Format</p>
          <p>Sample Format</p>
          <p>Detects</p>
          <p>Sample Volume</p>
          <p>Relative Sensitivity</p>
          <p>Relative Specificity</p>
          <p>Test Time</p>
          <p>Shelf Life</p>
          <p>Storage Temperature</p>
        </div>
        <div class="r">
          <p>Cassette</p>
          <p>Nasal swab</p>
          <p>Nucleocapsid protein antigen from SARS-CoV-2</p>
          <p>3 drops of specimen solution</p>
          <p>93.2% (96/103)</p>
          <p>99.2% (248/250)</p>
          <p>10-15 minutes</p>
          <p>24 Months</p>
          <p>2–30°C</p>
        </div>
      </div>
    </div>
    <div class="register">
      <p class="p1">Register your interest</p>
      <p class="p2" @click="learnMore(4)">Contact Us</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
  },
  data () {
    return {
      downImg: require('@/assets/images/down.png'),
      upImg: require('@/assets/images/up.png'),
      isShowSpecifi: false,
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0, 3.0], // 可选的播放速度
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: true, // 默认情况下将会消除任何音频。
        loop: true, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: 'video/mp4',
					src: require('@/assets/video/instructionalVideo.mp4')
        }],
        poster: '', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      }
    }
  },
  mounted() {
    document.title = 'btmedtec'
  },
  methods: {
    showDetail() {
      this.isShowSpecifi = !this.isShowSpecifi
    },
    learnMore(id) {
      this.$emit('switch-tab', id)
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  display: flex;
  flex-direction: column;
  .home-bg {
    width: 100%;
    min-height: 600px;
    background: url('../assets/images/home_banner.jpg') no-repeat center center;
    background-size: 100% auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    object-fit: fill;
    .spread {
      height: 395px;
      width: 730px;
      background: rgba(255,172,142, 0.8);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 5%;
      h1 {
        font-size: 40px;
        color: #fff;
        margin-top: 40px;
      }
      p {
        width: 100%;
        margin-top: 40px;
        font-size: 20px;
        color: #fff;
        text-align: left;
      }
    }
  }
  .more {
    width: 100%;
    height: 530px;
    background: #F5F5F5;
    display: flex;
    justify-content: space-evenly;
    .img {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      img {
        width: 500px;
        height: 320px;
        margin-right: 50px;
        margin-top: 25px;
      }
    }
    .describe {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: center;
      margin-left: 100px;
      h3 {
        width: 60%;
        font-size: 32px;
        font-weight: bold;
      }
      p {
        width: 80%;
        font-size: 18px;
        margin-top: 24px;
      }
      .btn {
        height: 58px;
        line-height: 58px;
        width: 300px;
        background: #ffac8e;
        color: #fff;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        border-radius: 35px;
        margin-top: 20px;
        cursor: pointer;
        &:hover {
          background: rgba(255, 172, 142, 0.4);
        }
      }
    }
  }
  .benefits {
    width: 100%;
    margin-bottom: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      width: 100%;
      height: 86px;
      line-height: 86px;
      text-align: center;
      font-size: 34px;
      font-weight: bold;
      margin-top: 20px;
    }
    .info {
      width: 90%;
      margin: 0 24px;
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
      .item {
        width: 270px;
        height: 100%;
        padding: 0 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          height: 88px;
          width: 88px;
        }
        h2 {
          font-size: 18px;
          margin-top: 10px;
        }
        p {
          font-size: 14px;
          margin-top: 10px;
        }
      }
    }
  }
  .video {
    width: 100%;
    height: 870px;
    background: #ffac8e;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      font-size: 34px;
      color: #fff;
      font-weight: bold;
      margin-top: 100px;
    }
    .play {
      width: 860px;
      height: 480px;
      background: #EEEEEE;
      margin-top: 50px;
    }
    .all {
      font-size: 18px;
      color: #fff;
      font-weight: bold;
      margin-top: 50px;
      padding: 10px 90px;
      border: 2px solid #fff;
      border-radius: 45px;
      cursor: pointer;
      &:hover {
        background: rgba(255, 255, 255, 0.4);
      }
    }
  }
  .works {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    .left {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      img {
        width: 80%;
      }
    }
    .right {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      h4 {
        font-size: 26px;
      }
      .known {
        margin-top: 14px;
        font-size: 18px;
        width: 80%;
      }
      .target {
        margin-top: 14px;
        font-size: 18px;
        margin-left: 10px;
        width: 80%;
      }
    }
  }
  .specifications {
    width: 100%;
    height: 118px;
    background: #F5F5F5;
    .switch {
      width: 90%;
      height: 60px;
      margin-top: 20px;
      margin-left: 5%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding-bottom: 10px;
      border-bottom: 2px solid #000;
      p {
        font-size: 18px;
        font-weight: bold;
      }
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  .list {
    width: 100%;
    height: 370px;
    background: #F5F5F5;
    .wrap {
      width: 90%;
      height: 60px;
      margin-left: 5%;
      display: flex;
      .l {
        width: 240px;
        display: flex;
        flex-direction: column;
      }
      .r {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      }
      p {
        font-size: 18px;
        font-weight: 400;
        height: 36px;
        line-height: 36px;
      }
    }
  }
  .register {
    width: 100%;
    height: 150px;
    background: #ffac8e;
    display: flex;
    align-items: center;
    justify-content: center;
    .p1 {
      margin-right: 40px;
      font-size: 36px;
      color: #fff;
      font-weight: 600;
    }
    .p2 {
      margin-left: 40px;
      font-size: 20px;
      color: #fff;
      font-weight: 400;
      padding: 12px 110px;
      border: 2px solid #fff;
      border-radius: 30px;
      cursor: pointer;
      &:hover {
        background: rgba(255, 255, 255, 0.4);
      }
    }
  }
}
</style>
