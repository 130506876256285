<template>
  <div class="content">
    <div class="contact-bg">
      <div class="spread">
        <p style="margin-top: 20px;">Contact Us</p>
      </div>
    </div>
    <div class="call">
      <p>We’re here to help – 24 hours a day, 7 days a week</p>
      <p>Call 1300 154668; info@btmedtec.au</p>
    </div>
    <div v-show="false" class="enquiry">
      <p class="title">Make an Enquiry</p>
      <input type="text" placeholder="Full name *" required>
      <input type="text" placeholder="Company / Organisation *" required>
      <input type="text" placeholder="Email *" required>
      <div class="input">
        <input style="width: 420px;" type="text" placeholder="Phone *" required>
        <input style="width: 420px;" type="text" placeholder="State *" required>
      </div>
      <el-input
        class="help"
        type="textarea"
        :rows="8"
        resize
        placeholder="How can we help you?"
      ></el-input>
      <div class="check">
        <el-checkbox v-model="checked"></el-checkbox>
        <p>I would like to receive future communications from OZ Surpahs Pty Ltd.</p>
      </div>
      <div class="submit">Submit</div>
      <p class="error">Please correct errors before submitting this form.</p>
    </div>
    <div class="wish">
      <div class="left">
        <p>If you wish to report poor performance or usability issues directly to the Therapeutic Goods Administration (TGA) via the Medical Device Incident Reporting scheme, email 
          <span @click="phone()">iris@tga.gov.au</span> or call <span @click="phone()">1800 809 361</span> (8:30 am to 5:00 pm AEST, Monday to Friday).</p>
      </div>
      <div class="right">
        <p>To contact your local state/territory health department click on the following link:</p>
        <p @click="toHealth()">https://www.health.gov.au/about-us/contact-us/local-state-and-territory-health-departments.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact',
  components: {
  },
  data() {
    return {
      checked: false
    }
  },
  methods: {
    //tab切换
    activityChange(id) {
      this.$emit('activity-change', id)
    },
    toHealth() {
      window.open('https://www.health.gov.au/about-us/contact-us/local-state-and-territory-health-departments', '_blank')
    },
    phone() {
      window.location.href = 'tel://' + '1800 809 361'
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  display: flex;
  flex-direction: column;
  .contact-bg {
    width: 100%;
    min-height: 500px;
    background: url('../assets/images/contact_bg.jpg') no-repeat center center;
    background-size: 100% auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    .spread {
      height: 200px;
      width: 550px;
      background: rgba(255,172,142, 0.8);
      display: flex;
      align-items: center;
      p {
        font-size: 40px;
        font-weight: 500;
        color: #fff;
        margin-left: 90px;
      }
    }
  }
  .call {
    width: 100%;
    height: 245px;
    background: #F5F5F5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      font-size: 34px;
      font-weight: 600;
      height: 60px;
      line-height: 60px;
    }
  }
  .enquiry {
    width: 100%;
    height: 980px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      margin-top: 105px;
      font-size: 32px;
      font-weight: 600;
    }
    input {
      height: 55px;
      width: 860px;
      border-radius: 28px;
      border: 1px solid #000;
      padding: 0 20px;
      margin-top: 25px;
    }
    .input {
      width: 860px;
      display: flex;
      justify-content: space-between;
    }
    .help {
      height: 250px;
      width: 860px;
      border-radius: 28px;
      border: 1px solid #000;
      padding: 10px 20px;
      margin-top: 25px;
    }
    .check {
      display: flex;
      align-items: center;
      margin-top: 30px;
      font-size: 18px;
    }
    .submit {
      font-size: 20px;
      color: #fff;
      font-weight: 400;
      margin-top: 30px;
      padding: 10px 100px;
      border-radius: 45px;
      cursor: pointer;
      background: #ffac8e;
      &:hover {
        background: rgba(255, 172, 142, 0.4);
      }
    }
    .error {
      color: #ee0000;
      font-size: 16px;
      margin-top: 40px;
      font-weight: 400;
    }
  }
  .wish {
    width: 100%;
    height: 236px;
    display: flex;
    justify-content: space-between;
    background: #F5F5F5;
    .left {
      width: 50%;
      margin-top: 55px;
      display: flex;
      justify-content: flex-end;
      p {
        width: 65%;
        margin-right: 30px;
        font-size: 18px;
        font-weight: 400;
        span {
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
    .right {
      width: 50%;
      margin-top: 55px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      p {
        width: 66%;
        margin-left: 30px;
        font-size: 18px;
        font-weight: 400;
      }
      p:nth-child(2) {
        font-weight: bold;
        margin-top: 10px;
        cursor: pointer;
      }
    }
  }
  ::v-deep .el-textarea__inner {
    border: 0 solid #fff;
  }
}
</style>