<template>
  <div class="content">
    <div class="about-bg">
      <div class="spread">
        <p style="margin-top: 20px;">About Us</p>
      </div>
    </div>
    <div class="company">
      <div class="img">
        <img src="../assets/images/company.jpg">
      </div>
      <div class="describe">
<!--        <h3>The Company</h3>-->
        <p>As an Australian in vitro diagnostics company, we help enable better diagnostic decisions in human health, animal diagnostics and environmental and agriculture testing.</p>
      </div>
    </div>
    <div class="covid">
      <div class="describe">
        <h3>COVID-19</h3>
        <p>With the more recent events around the COVID-19 pandemic, AusDiagnostics partnered with Biotest, a leader in immunoassay development, to bring solutions for COVID-19 self-testing to directly support community health and safety as well as protecting local businesses and enterprises.</p>
      </div>
      <div class="img">
        <img src="../assets/images/covid.jpg">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutUs',
  components: {
  },
  data () {
    return {
    }
  },
  mounted() {
    document.title = 'btmedtec'
  },
  methods: {
    //tab切换
    activityChange(id) {
      this.$emit('activity-change', id)
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  display: flex;
  flex-direction: column;
  .about-bg {
    width: 100%;
    min-height: 500px;
    background: url('../assets/images/about_bg.jpg') no-repeat center center;
    background-size: 100% auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    .spread {
      height: 200px;
      width: 550px;
      background: rgba(255,172,142, 0.8);
      display: flex;
      align-items: center;
      p {
        font-size: 40px;
        font-weight: 500;
        color: #fff;
        margin-left: 90px;
      }
    }
  }
  .company {
    width: 100%;
    height: 600px;
    background: #F5F5F5;
    display: flex;
    justify-content: space-evenly;
    .img {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      img {
        width: 80%;
      }
    }
    .describe {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: center;
      margin-left: 100px;
      h3 {
        width: 60%;
        font-size: 32px;
        font-weight: bold;
      }
      p {
        width: 80%;
        font-size: 18px;
        margin-top: 24px;
      }
    }
  }
  .covid {
    width: 100%;
    height: 620px;
    display: flex;
    justify-content: space-evenly;
    .describe {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: center;
      margin-left: 200px;
      h3 {
        width: 60%;
        font-size: 32px;
        font-weight: bold;
      }
      p {
        width: 80%;
        font-size: 18px;
        margin-top: 24px;
      }
    }
    .img {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img {
        width: 80%;
      }
    }
  }
}
</style>