<template>
  <div class="site">
    <div class="navbar">
      <img src="../assets/images/logo2.png">
      <div class="nav-header">
        <span :class="{'active': activityId === 1}" @click="switchTab(1)">Home</span>
        <span :class="{'active': activityId === 2}" @click="switchTab(2)">Resources</span>
        <span :class="{'active': activityId === 3}" @click="switchTab(3)">About Us</span>
        <span :class="{'active': activityId === 4}" @click="switchTab(4)">Contact</span>
      </div>
    </div>
    <home @switch-tab="activityChange" v-if="activityId === 1"></home>
    <resources v-if="activityId === 2"></resources>
    <aboutUs v-if="activityId === 3"></aboutUs>
    <contact v-if="activityId === 4"></contact>
    <bottom @activity-change="activityChange"></bottom>
  </div>
</template>

<script>
import home from '../components/home'
import resources from '../components/resources'
import aboutUs from '../components/aboutUs'
import contact from '../components/contact'
import bottom from '../components/bottom'
export default {
  name: 'index',
  components: {
    home,
    resources,
    aboutUs,
    contact,
    bottom
  },
  data() {
    return {
      activityId: 1
    }
  },
  mounted () {


  },
  methods: {
    switchTab(id) {
      this.activityId = id
    },
    //tab切换
    activityChange(id) {
      this.activityId = id
      document.body.scrollTop = document.documentElement.scrollTop = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  min-width: 1280px;
}
.site {
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}
.navbar {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 88px;
  min-height: 88px;
  background: #003F68;
  img {
    height: 80px;
    margin-left: 20px;
  }
  .nav-header {
    width: 400px;
    height: 100%;
    margin-right: 24px;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content:space-evenly;
    span {
      display: flex;
      align-items: center;
      height: 100%;
      cursor: pointer;
    }
    .active {
      font-size: 22px;
      font-weight: 600;
      color: #ffac8e;
    }
  }
}
.bottom {
  width: 100%;
  height: 306px;
  background: #003F68;
}
</style>
