<template>
  <div class="bottom">
    <div class="logo">
      <img src="../assets/images/logo2.png">
    </div>
    <div class="action">
      <div class="wrap">
        <div class="home">
          <p @click="switchTab(1)">Home</p>
        </div>
        <div class="home">
          <p @click="switchTab(2)">Resources</p>
        </div>
        <div class="home">
          <p @click="switchTab(3)">About Us</p>
        </div>
        <div class="home">
          <p @click="switchTab(4)">Contact</p>
        </div>
      </div>
      <div class="visit">
        <!-- <p>If you are a business or healthcare professional, please visit</p>
        <a href="www.baidu.com">www.baidu.com</a> -->
      </div>
      <div class="care">
        <p>©BT Medtec Pty Ltd {{year}}. All rights reserved  |  Privacy Policy  |  Terms & Conditions</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Bottom',
  data () {
    return {
      year: ''
    }
  },
  mounted() {
    this.year = new Date().getFullYear()
  },
  methods: {
    switchTab(id) {
      this.$emit('activity-change', id)
    }
  }
}
</script>

<style lang="less" scoped>
.bottom {
  width: 100%;
  min-height: 150px;
  background: hsl(204, 100%, 20%);
  display: flex;
  flex-direction: row;
  .logo {
    width: 30%;
    height: 100%;
    display: flex;
    margin-left: 70px;
    img {
      height: 80px;
      margin-top: 45px;
    }
  }
  .action {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin: 20px;
    .wrap {
      display: flex;
      .home {
        width: 164px;
        height: 40px;
        line-height: 40px;
        color: #fff;
        font-size: 20px;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        p {
          cursor: pointer;
          &:hover {
            color: #ffac8e;
          }
        }
      }
    }
    .visit {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      display: none;
      margin-top: 85px;
      a {
        margin-left: 10px;
        color: #fff;
      }
    }
    .care {
      color: #fff;
      font-size: 16px;
      font-weight: 400;
      display: flex;
      margin-top: 30px;
    }
  }
}
</style>